import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { setSelectedMode } from '../../actions/localStates'

import i18next from 'i18next'

class JavaDownloadPopup extends Component {
	constructor(props) {
	    super(props);
	    this.state = {

	    }

	    this.redirectToJava = this.redirectToJava.bind(this)
		this.redirectToManual = this.redirectToManual.bind(this)
	}

	componentDidMount() {

	}

	redirectToManual () {
		window.open("https://docs.cipher.com.ua/x/oIPxAQ", '_blank');
	}

	redirectToJava () {
		window.open("https://java.com/ru/download/manual.jsp", '_blank');
	}

	close () {
		if (!this.props.connectionStatus.uccConnection) {
	      this.props.actions.setSelectedMode(0)
	    }
		this.props.closeJavaDownloadPopup()
	}

	goToSource (url) {
	    window.open(url, '_blank').focus();
	}

	render() {
		let mode = parseInt(this.props.pkLocalState.selectedMode, 10);
		return (
			<div className='popup'>
	        <div className='popup_inner' style={{width: "470px", height: "350px"}}>
				<div className="card">
	              	<div className="card-header">
	              	{
	              		mode === 1
          				? i18next.t("pk:pkcsDevicesActiveTitle")
          				: mode === 2
          				? i18next.t("pk:pkcsDevicesPassiveTitle")
          				: ""
	              	}
	              	
	              	</div>
	              	<div className="card-body">
          			<span>
          				{i18next.t("infoTitleJnlp")}
          			</span>
          			<span>{i18next.t("infoTitleJnlpDownload")}</span>
          			<span>{i18next.t("infoTitleJava")}</span>
          			<span><a href="https://docs.cipher.com.ua/pages/viewpage.action?pageId=32605088#id-%D0%86%D0%BD%D1%81%D1%82%D1%80%D1%83%D0%BA%D1%86%D1%96%D1%8F%D0%B7%D1%83%D1%81%D1%82%D0%B0%D0%BD%D0%BE%D0%B2%D0%BA%D0%B8%D1%82%D0%B0%D0%B5%D0%BA%D1%81%D0%BF%D0%BB%D1%83%D0%B0%D1%82%D0%B0%D1%86%D1%96%D1%97%D0%90%D0%B3%D0%B5%D0%BD%D1%82%D1%83%D0%A8%D0%B8%D1%84%D1%80CaaS-%D0%9F%D1%96%D0%B4%D0%B3%D0%BE%D1%82%D0%BE%D0%B2%D0%BA%D0%B0%D1%80%D0%BE%D0%B1%D0%BE%D1%87%D0%BE%D0%B3%D0%BE%D0%BC%D1%96%D1%81%D1%86%D1%8F%D0%B4%D0%BB%D1%8F%D1%80%D0%BE%D0%B1%D0%BE%D1%82%D0%B8%D1%96%D0%B7%D0%B7%D0%B0%D1%81%D1%82%D0%BE%D1%81%D1%83%D0%BD%D0%BA%D0%BE%D0%BC%C2%AB%D0%90%D0%B3%D0%B5%D0%BD%D1%82%D0%84%D0%A1%D0%9A%D0%9E%C2%BB" target="_blank" rel="noopener noreferrer">{i18next.t("infoTitleRedirectToManual")}</a></span>

          			<p style={{"margin": "0px"}}></p>
          			<span>{i18next.t("infoTitleManualDownload")}</span>

          			{
				        this.props.base.uosAgentNativeBuilds !== undefined && this.props.base.uosAgentNativeBuilds.length > 0 && !this.props.mobileModeReducer.isMobileDevice && this.props.base.bodyIsMounted
				        ? <div style={{textAlign: "center", paddingTop: "10px"}}>
				            {
				              this.props.base.uosAgentNativeBuilds.map((source) => {
				                var name = "", _this = this, counter = 0;
				                _this.props.base.uosAgentNativeBuilds.forEach((data) => {
				                	if ((data.name.indexOf("windows32") !== -1) || (data.name.indexOf("windows64") !== -1)) {
				                		counter += 1;
				                	}
				                })

				                name = source.name
				                if (source.name.indexOf("windows32") !== -1) {
				                  name = "windows"
				                }

				                if (source.name.indexOf("windows64") !== -1) {
				                  name = "windows64"
				                }

				                if (source.name.indexOf("linux") !== -1) {
				                  name = "linux"
				                }

				                if (source.name.indexOf("macos") !== -1) {
				                  name = "apple"
				                }

				                return <button className="btn right-download-button" style={{fontSize: "10px", color: "#777"}} onClick={this.goToSource.bind(this, source.url)}><i className={"fa " + "fa-" + (name === "windows64" ? "windows" : name)} style={{fontSize: "1.2rem", color: "#888"}}></i>{name === "windows64" ? "x64" : ((counter > 1 && name === "windows64") || (counter > 1 && name === "windows")) ? "x32" : ""}</button>
				              })
				            }
				            
				          </div>
				        : null
				      }

				    <div style={{textAlign: "right"}}>
	                	<button className="btn btn-default" style={{width: "52px"}} onClick={this.close.bind(this)}>{i18next.t("pk:selectTitle")}</button>
	                </div>
	              </div>
	            </div>
	        </div>
	    </div>
		);
	}
}

function mapStateToProps(state) {
    return {
        base: state.base,
        connectionStatus: state.connectionStatusReucer,
        mobileModeReducer: state.mobileModeReducer,
        pkLocalState: state.privateKeyReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
    	setSelectedMode
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(JavaDownloadPopup);